<template>
  <FocusTrap>
    <div class="card" v-on:keydown="movefocus($event)">

      <div class="card-header header-elements-sm-inline py-sm-0" style="background-color: whitesmoke;padding-left: 10px!important;" >

        <h5 class="card-title py-sm-2" ><span class="icon-list"></span>&nbsp;&nbsp;Periodicity List</h5>

        <div class="header-elements" >
          <form action="#">
            <div class="form-periodicity-feedback form-periodicity-feedback-right">
              <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
              <div class="form-control-feedback">
                <i class="icon-search4 font-size-base text-muted"></i>
              </div>
            </div>
          </form>

        </div>
      </div>

      <div class="card-body" style="padding: 0px;">
        <div class="table-responsive">
          <table id="periodicity_table"
                 class="table table-bordered table-condensed table-columned"
                 data-search="false"
                 data-pagination="true"
                 stickyHeader="true"
                 data-show-refresh="false"
                 data-show-columns="false"
                 data-page-list="[10, 25, 50, 100, ALL]"
                 data-show-footer="false"
                 data-toggle="context"
                 data-target=".context-table">
            <thead>
            <tr>
              <th data-field="id" data-class="d-none">id</th>
              <th data-formatter="runningFormatter" data-width="75">S.No</th>
              <th data-field="name" data-sortable="true">Name</th>
              <th data-field="parent_name" data-width="400" data-sortable="true">Parent</th>
            </tr>
            </thead>
            <tbody>
            </tbody>
            <tfoot>
            </tfoot>
          </table>
        </div>
      </div>

      <!-- Context Menu -->
      <div id="context-menu" class="context-table">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3" ></i>Modify</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" > <i class="icon-blocked text-danger" ></i>Delete</a>
        </div>
      </div>
      <!-- End of Context Menu -->


      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
            <i class="fab-icon-open icon-plus3"></i>
            <i class="fab-icon-close icon-plus3"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->

      <vmodal id="periodicity_window" name="periodicity_window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="450" :height="235" @before-open="beforeOpen" @before-close="beforeClose" >
        <PeriodicityForm v-bind:mygroup="periodicity" v-on:periodicity_saved="loadData" v-on:periodicity_window_closed="closeModal"  v-on:periodicity_window_resized="periodicity_window_resized" ></PeriodicityForm>
      </vmodal>

    </div>
  </FocusTrap>
</template>

<script>

import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import PeriodicityForm from './PeriodicityForm'

export default {
  name: 'PeriodicityView',
  components: {
    PeriodicityForm
  },
  store,
  data () {
    return {
      mytable: {},
      periodicity: JSON.parse('{"id":0,"status":"Active","type":0,"name":"","itervel":1,"duration":""}')
    }
  },
  created () {

  },
  mounted () {
    const self = this;

    this.$data.mytable = $('#periodicity_table');

    this.$data.mytable.bootstrapTable();
    $('[data-toggle="popover"]').popover();

    $('#periodicity_table').contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        let id = $(row.children('*')[0]).text();

        if ($(e.target).text() === 'Modify') {
          self.modifyDocument(id);
        }else if ($(e.target).text() === 'Delete') {
          self.removeDocument(id);
        }
      }
    });

    $('#txtsearch').keyup(function () {

      let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
      $('#periodicity_table>tbody>tr').show().filter(function () {
        let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
        return !~text.indexOf(val);
      }).hide();

    });

    self.loadData();
  },
  methods: {
    movefocus (evt) {
      const self = this;

      let keycode = (evt.keyCode ? evt.keyCode : evt.which)

      if (keycode == '13' || keycode == '40') {

        evt.preventDefault(true);
        evt.stopPropagation(true);
        evt.stopped = true;

        nextInput();
      }else if (keycode == '38') {
        evt.preventDefault(true);
        evt.stopPropagation(true);
        evt.stopped = true;
        priviousInput();

      } else if (keycode == '120') {
        evt.preventDefault(true)
        evt.stopPropagation(true)
        evt.stopped = true;

        self.perform_clear_action();

      } else if (keycode == '121') {
        evt.preventDefault(true)
        evt.stopPropagation(true)
        evt.stopped = true

        self.perform_save_action();

      }
    },
    closeModal () {
      this.$modal.hide('periodicity_window')
    },
    beforeOpen(){

    },
    beforeClose(){

    },
    showModal () {
      this.periodicity = JSON.parse('{"id":0,"status":"Active","type":0,"name":"","itervel":1,"duration":""}');
      this.$modal.show('periodicity_window');
    },
    modifyDocument (id) {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.periodicity = {};
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/periodicity/${id}`,requestOptions).then(userService.handleResponse).then(function (data) {
        if(data.ok) {
          self.$data.periodicity = data.data;
          self.$modal.show('periodicity_window');
        } else {
          swal ( { title: "Oops!" ,  text: data.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      });
    },
    removeDocument (id) {
      const self = this;

      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.periodicity.id = id;

      const requestOptions = {
        method: 'DELETE',
        mode:'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.periodicity)
      };

      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        timer: 3000,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete tms!'
      }).then((result) => {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}api/lms/periodicity`,requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('periodicity_window')
            self.loadData();
            swal(
              'Deleted!',
              'Your request has been processed',
              'success'
            );
            // swal ( { title: "Success!" ,  text: data.msg, type:  "success"} );
          }).catch(function (err) {
            swal({ title: "Oops", text: err.toString(), type: "error" });
          }).finally(function () {
            $(self.$data.mytable).unblock();
          });
        }
      });

    },
    loadData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };


      this.$modal.hide('periodicity_window');

      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.mytable.bootstrapTable('load',[]);

      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/periodicitys/after/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          self.$data.mytable.bootstrapTable('load', resp.data);
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $(self.$data.mytable).unblock();
      });

    },
  }

}
</script>

<style scoped>
input:focus {
  background: #feff00;
}

textarea:focus {
  background: #feff00;
}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

div:focus {
  background: #feff00;
}
</style>
