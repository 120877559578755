<template>
  <FocusTrap>
    <!-- Basic layout-->
    <div id="mycard" class="card">
      <div class="card-header header-elements-inline py-sm-2" style="background-color: whitesmoke;  padding: 5px;">
        <h5 class="card-title">Periodicity Creation</h5>
        <div class="header-elements">
          <div class="list-icons">
<!--            <a class="list-icons-item" data-action="reload" @click="loadParents"></a>-->
            <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
          </div>
        </div>
      </div>

      <div class="card-body">
        <p/>

        <div class="form-periodicity row">
          <label class="col-md-2 col-form-label">Name:</label>
          <div class="col-md-10">
            <input id="txtname" ref="txtName" type="text" class="form-control" placeholder="Enter Periodicity Name here..." maxlength="100" v-if="periodicity" v-model="periodicity.name" autocomplete="off" >
          </div>
        </div>

        <div class="form-periodicity row">
          <label class="col-md-2 col-form-label">Intervel:</label>
          <div class="col-md-10">
            <select class="form-control" ref="cmbIntervel"  v-if="periodicity" v-model="periodicity.intervel"  >
              <option value="1" selected>Day</option>
              <option value="2" >Weekly</option>
              <option value="3" >Monthly</option>
            </select>
          </div>
        </div>

        <div class="form-periodicity row">
          <label class="col-md-2 col-form-label">Duration</label>
          <div class="col-md-10">
            <input class="form-control" ref="txtDuration"  type="number" v-if="periodicity" v-model="periodicity.duration"  />
          </div>
        </div>


      </div>
      <div class="card-footer" style="padding: 3px;">
        <div class="text-right" >
          <SaveButton @handle_save_action="perform_save_action"/>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </FocusTrap>
</template>

<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import SaveButton from '../../../components/core/buttons/SaveButton'
export default {
  name: 'PeriodicityForm',
  components: {
    SaveButton
  },
  store,
  props: {
    myperiodicity: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"status":"Active","type":0,"name":"","itervel":1,"duration":""}')
    }
  },
  beforeMount () {
    this.periodicity = this.myperiodicity; // save props data to itself's data and deal with tms
  },
  data () {
    return {
      periodicity: JSON.parse('{"id":0,"status":"Active","type":0,"name":"","itervel":1,"duration":""}'),
      bookgroups: [],
    }
  },
  created () {
    const self = this;
    self.loadParents();
  },
  mounted () {
    const self = this;

    $('#txtname').focus();
    $('.form-control-select2').select2();
    $('.form-input-styled').uniform({
      fileButtonClass: 'action btn bg-pink-400'
    });
    $('.form-control-styled').uniform();

  },
  methods: {

    loadParents(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/groups`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          self.$data.bookgroups =  resp.data;
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: `failed to load data!`, type:  "error"} );
      });
    },
    closeThis(){
      this.$emit('periodicity_window_closed');
    },
    perform_save_action() {
      const self = this;

      try {
        $(self.$data.mycard).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        if (self.$data.periodicity.name.trim().length < 1) {
          alert("Invalid Name");
          self.$refs.txtName.focus();
          return;
        } else if (self.$data.periodicity.intervel < 1) {
          alert("Invalid Intervel");
          self.$refs.cmbIntervel.focus();
          return
        }

        const requestOptions = {
          method: (self.$data.periodicity.id == 0 ? 'POST' : 'PUT'),
          mode: "cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.periodicity)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/periodicity`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({ title: "Success!", text: resp.msg, type: "success", timer: 3000 });
            self.periodicity = JSON.parse('{"id":0,"status":"Active","type":0,"name":"","itervel":1,"duration":""}');
            self.$emit('periodicity_saved', resp.data);
          } else {
            swal({ title: "Oh noes!", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: `failed to load data!`, type: "error" });
        });
        $(self.$data.mycard).unblock();
      }catch (e) {
        alert(e);
      }finally {
        $(self.$data.mycard).unblock();
      }
    }
  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;
}

textarea:focus {
  background: #feff00;
}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

div:focus {
  background: #feff00;
}
</style>
